import React from "react";

export const Terms = (props) => {
  return (
    <div className="terms">
      <div className="container">
        <div className="row">
          <h2>Terms Of Service</h2>
        </div>
        <div className="row">
          These Terms of Service (the “Terms”) are an agreement between you, a
          user of our website or applications and {window.env.Name}, LLC (,
          “us”, “we”, or “our”).
          <br />
          <br />
          We will periodically update or amend any of the terms in this
          agreement. Changes will be effective upon publishing the revised Terms
          of Service and End-User License Agreement on our website. If you do
          not wish to accept the amended agreement, you have to cancel your
          access to our products. Continuing to use our software accessed
          through the website in your personal account constitutes your
          acceptance of the terms of the modified agreement. Disclaimer of
          Warranty: <br />
          <br />
          WE MAKES NO REPRESENTATION OR WARRANTIES WITH RESPECT TO THE WEBSITE.
          THE WEBSITE AND ALL INFORMATION, CONTENT, MATERIALS, AND SOFTWARE ARE
          PROVIDED TO YOU AS IS AND AS RECEIVED. TO THE FULLEST EXTENT PERMITTED
          BY LAW, WE DISCLAIMS ALL WARRANTIES AND CONDITIONS, LEGAL, EXPRESSED
          OR IMPLIED, BY OPERATION OF LAW OR OTHERWISE.
        </div>
        <div className="row">
          <h2>LIMITATION OF LIABILITY</h2>
        </div>
        <div className="row">
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, Our company AND ITS
          AFFILIATES AND SUBSIDIARIES SHALL NOT BE LIABLE FOR LOSS OF OR DAMAGE
          TO PROPERTY, LOSS OF DATA, LOSS OF INCOME, FAILURE OF PERFORMANCE, AND
          GOODWILL. <br />
          <br />
          Indemnification by You: To the fullest extent permitted by law, you
          agree to defend and hold harmless our website from and against any and
          all claims, losses, charges, costs, and liability that may arise from
          your use of our software service.LICENSE GRANT Subject to the terms
          and conditions of this Terms, we grant you a non-exclusive, revocable,
          non-transferable, personal, limited license, to (a) download and
          install the most current available version of the Application and
          Services offered to you, and (b) use the Services solely for your
          personal, non-commercial purposes. The Services may not be used for
          any other purpose without Company’s prior written consent. If you are
          installing a version of the Application that includes third party
          features and functionalities or accesses third party content, such
          third-party features, functionalities and content are subject to such
          third party's terms of service and are not governed by these Terms.
        </div>
        <div className="row">
          <h2>Age Requirement</h2>
        </div>
        <div className="row">
          Our website is only available to people who are at least 13 years old.
          Those under the age of 13 should have their parents or legal guardians
          review and discuss this agreement with them before using the website.
        </div>
        <div className="row">
          <h2>RESTRICTIONS OF USE</h2>
        </div>
        <div className="row">
          You may not rent, sell, lease, sublicense, distribute, market, assign,
          copy, or in any way transfer the Service or use the Application for
          the benefit of any third party in any manner. You may not modify,
          decompile, disassemble, or otherwise reverse-engineer the source code
          of the Application or attempt to do so for any reason. Further, you
          may not access, create or modify the source code of the Application in
          any way. You do not have the right to and may not create derivative
          works of the Service. All modifications or enhancements to the
          Application remain our sole property. You understand that we, in our
          sole discretion, may modify or discontinue or suspend your right to
          access any of our Services at any time, and we may at any time suspend
          or terminate any license hereunder and disable the Application or any
          of its component features. You represent and warrant that you are
          either the owner or an authorized user of the computer where the
          Application is installed. You may use the Services only for lawful
          purposes. The Services are subject to, and you agree that you shall at
          all times comply with, all local, state, national, and international
          laws, statutes, rules, regulations, ordinances and the like applicable
          to the use of our Services. You agree not to use the Services to
          conduct any business or activity or solicit the performance of any
          activity, which is prohibited by law or by any contractual provision
          by which you are bound. You further agree that you will not use any
          robot, spider, other automatic or manual device or process to
          interfere or attempt to interfere with the proper working of any of
          the Services, except to disable or remove our Application from a
          computer of which you are an owner or authorized user. You may not
          violate or attempt to violate the security of our Application. We
          reserve the right to investigate occurrences which may involve such
          violations, and may involve and cooperate with law enforcement
          authorities in prosecuting users who have participated in such
          violations. You agree that it is your responsibility to install
          anti-virus software and related protections against viruses, Trojan
          horses, worms, time bombs, cancelbots or other techniques that may
          have the effect of damaging, destroying, disrupting or otherwise
          impairing a computer's functionality or operation.
        </div>
        <div className="row">
          <h2>UPDATES</h2>
        </div>
        <div className="row">
          We reserve the right to add or remove features or functions to or from
          the Service. When installed on your computer, the Application
          periodically communicates with our servers to request automatic
          updates when we release a new version of the Application, or when we
          make new features available. you hereby agree that the company may
          automatically download and install updates to the Application, from
          time to time, without prior notification. These updates are designed
          to improve, enhance and further develop the Application and may take
          the form of bug fixes, enhanced functions, new software modules and
          completely new versions. you agree to receive such updates as part of
          your use of the Application. In the event, we believe that such
          updates or upgrades shall materially affect your use of the Services,
          we will make best efforts to notify you. These updates shall be
          controlled by your device settings.
        </div>
        <div className="row">
          <h2>OWNERSHIP AND INTELLECTUAL PROPERTY RIGHTS</h2>
        </div>
        <div className="row">
          You acknowledge and agree that the Application, the Site and any
          associated components are licensed, and were not sold to you. You also
          acknowledge that all trademarks, service marks, the Application,
          including all associated components, code, protocols, software and
          documentation provided to you by us in conjunction with the Services
          are the Company’s property or the property of our licensors, and are
          protected by international copyright, trademarks, patents and other
          proprietary rights and laws relating to trade secrets, and any other
          intellectual property or proprietary rights recognized in any country
          or jurisdiction worldwide, including, without limitation, moral or
          similar rights. You may not use, delete, alter, or remove any
          copyright, trademark, or other proprietary rights notice we have
          placed on the Application or associated components. All rights not
          expressly granted hereunder are expressly reserved to us and our
          licensors. PRIVACY POLICY By Using the Application, you may enable us
          or third parties to access, use, and collect a variety of information,
          both personal and non-personal, regarding your Internet Browser, your
          browsing habits, and information about your computer including by
          usage of cookies. For more details on the data collected and privacy
          methods we recommend you read our Privacy Policy available here. Our
          Privacy Policy contains information about the data protection policies
          and explains how we access, collect, use, or share information. By
          downloading and using any of the Services, you consent to the Privacy
          Policy. We prohibit the violation of a user's privacy rights and the
          distribution of materials that are illegal, infringing, or may be
          deemed offensive by us, in our sole discretion. DISCLAIMER OF WARRANTY
          YOUR ACCESS AND USE OF THE Application, SERVICES AND ALL FEATURES,
          FUNCTIONALITY OR CONTENT PROVIDED THEREIN OR THERETHROUGH ARE AT YOUR
          SOLE RISK. WE PROVIDE THE SERVICE ON AN "AS IS," AND "AS AVAILABLE"
          BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS, IMPLIED OR
          STATUTORY, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, CUSTOM,
          TRADE, QUIET ENJOYMENT, ACCURACY OF INFORMATIONAL CONTENT, SYSTEM
          INTEGRATION OR NON-INFRINGEMENT. WE DO NOT MAKE ANY REPRESENTATION OR
          WARRANTY: (A) AS TO THE TIMELINESS, SEQUENCE, ACCURACY, COMPLETENESS,
          OR RELIABILITY OF THE SERVICE, (B) THAT THE Application WILL BE
          AVAILABLE OR WILL OPERATE IN AN UNINTERRUPTED OR ERROR-FREE MANNER, OR
          (C) THAT ERRORS OR DEFECTS RELATED TO THE Application WILL BE
          CORRECTED. WE ALSO DO NOT WARRANT THAT THE Application, OR THE
          INFORMATION AVAILABLE THROUGH the Application, IS APPROPRIATE,
          ACCURATE OR AVAILABLE FOR USE IN ANY PARTICULAR JURISDICTION. EXCEPT
          AS EXPRESSLY STATED HEREIN AND IN OUR PRIVACY POLICY, THE COMPANY DOES
          NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND,
          EXPRESS OR IMPLIED, AS TO THE SECURITY OF ANY INFORMATION YOU MAY
          PROVIDE OR ACTIVITIES YOU ENGAGE IN DURING THE COURSE OF YOUR USE OF
          THE SERVICE. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF CERTAIN
          WARRANTIES, SO TO THAT EXTENT CERTAIN EXCLUSIONS SET FORTH HEREIN MAY
          NOT APPLY. LIMITATION OF LIABILITY YOU EXPRESSLY ACKNOWLEDGE AND AGREE
          THAT IN NO EVENT SHALL THE COMPANY (INCLUDING, WITHOUT LIMITATION, ITS
          AFFILIATES AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND
          AGENTS), AS WELL AS ITS VENDORS, DISTRIBUTORS, THIRD PARTY LICENSORS,
          OR EQUIPMENT AND SERVICE PROVIDERS BE LIABLE FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
          INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST PROFITS, LOST BUSINESS
          OR LOST OPPORTUNITY, GOODWILL, OR OTHER INTANGIBLE LOSSES (EVEN IF WE
          HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) OR OTHER RELIEF
          ARISING OUT OF, OR RELATED TO, THESE TERMS OR TO YOUR USE OR THE
          INABILITY TO USE THE SERVICE. YOU FURTHER ACKNOWLEDGE AND AGREE THAT
          THE COMPANY MAY CHANGE THE SERVICE IN WHOLE OR IN PART IN ITS SOLE
          DISCRETION WITHOUT NOTICE TO YOU AND WITHOUT ANY LIABILITY TO YOU
          WHATSOEVER IN CONNECTION THEREWITH. COMPANY’S LIABILITY SHALL NOT
          EXCEED THE COST OF THE SERVICE. BECAUSE SOME STATES OR JURISDICTIONS
          DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR DAMAGES,
          IN SUCH STATE OR JURISDICTIONS, OUR LIABILITY SHALL BE LIMITED TO THE
          EXTENT PERMITTED BY LAW. TERMINATION You can terminate these Terms and
          the use of the Service at any time. If you wish to terminate your use
          of the Service, you may do so by uninstalling the Application from
          your device, by using the standard uninstall processes that are
          available as part of your operating system or through your Browser’s
          Settings, as applicable. We may terminate your access to
          theApplication or Services at any time, with or without cause and with
          or without notice, effective immediately. Such termination may result
          in the destruction of all information and data associated with your
          use of the Application and Services.
        </div>
        <div className="row">
          <h2>DISPUTE RESOLUTION</h2>
        </div>
        <div className="row">
          For any dispute you have with us, you agree to first contact us
          Contact Us and attempt to resolve the dispute with us informally. If
          we were not able to resolve the dispute with you informally, we each
          agree by these enforceable Terms, to resolve any claim, and unless
          otherwise required by a mandatory law dispute or controversy arising
          out of or in connection with or relating to these Terms by binding and
          exclusively arbitration by the American Arbitration Association
          (“AAA”). ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL
          CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
          CLASS OR REPRESENTATIVE PROCEEDING AND THE ARBITRATOR MAY NOT
          CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING
          INTO THESE TERMS, YOU AND US ARE EACH WAIVING THE RIGHT TO A TRIAL BY
          JURY OR TO PARTICIPATE IN A CLASS ACTION. You must include your name
          and residence address, and a clear statement that you want to opt out
          of this arbitration agreement. This arbitration agreement will survive
          the termination of these Terms. These Terms are governed by and
          construed in accordance with the laws of the State of New York,
          without giving effect to any principles of conflicts of law and will
          specifically not be governed by the united nations conventions on
          contracts for the international sale of goods, if otherwise
          applicable. For any action at law or in equity relating to the
          arbitration provision of these Terms and Conditions, you agree to
          resolve any dispute you have with exclusively in a state or federal
          court located in New York, Manhattan and to submit to the personal
          jurisdiction of the courts located in New York County for the purpose
          of litigating all such disputes. Any cause of action you might have
          relating to the service is limited in time to one (1) year from the
          arising incident, and will be permanently barred afterwards.
        </div>
        <div className="row">
          <h2>MISCELLANEOUS</h2>
        </div>
        <div className="row">
          These Terms, constitutes the entire understanding between the parties
          with respect to the use of the Service. If any provision of these
          Terms is held to be unenforceable, such provision shall be reformed
          only to the extent necessary to make it enforceable and such decision
          shall not affect the enforceability of such provision under other
          circumstances, or of the remaining provisions hereof under all
          circumstances. Failure of the Company to enforce any rights or to take
          action against you in the event of any breach hereunder shall not be
          deemed as a waiver of such rights or of subsequent actions in the
          event of future breaches. This EULA and any right granted herein may
          not be assigned by you without the prior written consent of the
          Company. We are constantly scaling in order to provide the best
          possible experience for our users. You acknowledge and agree that the
          form, features or nature of the website, product or plugin which
          Licensor provides may change from time to time without prior notice to
          you. We may also cease or discontinue providing the product or
          upgrades at any time. For details about our data collection and data
          use practices, please see our Privacy Policy. As explained in the
          Privacy Policy, Your web sessions may be tracked and stored for the
          purpose of displaying targeted advertising. Please follow the
          instructions found on the uninstall page to uninstall the Product.
          <br />
          <br />
          Twobi media ltd.
          <br />
          support@twobi.net
        </div>
      </div>
    </div>
  );
};
